
import { useStore } from "vuex";
import { computed, defineComponent, onMounted } from "vue";
import BasicLayout from "@/layout/BasicLayout.vue";
import TheoryQuestions from "@/views/Guest/TheoryQuestions.vue";
import isUndefined from "lodash/isUndefined";
import { ICrudOptions } from "@/interfaces/ICrudOptions";

export default defineComponent({
  name: "BasicTheoryQuestions",
  components: { TheoryQuestions, BasicLayout },
  setup() {
    const store = useStore();

    const findRandomTheoryQuestion = async () => {
      await store.dispatch("theory-questions/guest/findOne", {
        resource: "/theory-questions/random",
      });
    };

    const reset = async () => {
      await store.dispatch("theory-questions/guest/reset");
    };

    const randomTheoryQuestion = computed<any>(() => store.getters["theory-questions/guest/getDataItem"]);
    const randomTheoryQuestionLoading = computed(() => store.getters["theory-questions/guest/getIsLoading"]);

    onMounted(() => {
      goToNext();
    });

    const goToNext = () => {
      reset();
      findRandomTheoryQuestion();
    };

    const studentQuestionAnswersDemo = async (opt: ICrudOptions) => {
      await store.dispatch("student-question-answers/demo/create", opt);
    };

    const onAnswer = (answerData: any) => {
      if (!randomTheoryQuestion.value?.id) return;
      const data: any = {
        theoryQuestionId: randomTheoryQuestion.value.id,
        correct: answerData.correct,
      };

      if (!isUndefined(answerData.numberOfTimesVideoWasPlayed)) {
        data.numberOfTimesVideoWasPlayed = answerData.numberOfTimesVideoWasPlayed;
      }
      if (!isUndefined(answerData.numberOfTimesPictureWasEnlarged)) {
        data.numberOfTimesPictureWasEnlarged = answerData.numberOfTimesPictureWasEnlarged;
      }

      studentQuestionAnswersDemo({
        resource: "student-question-answers/demo",
        hideSuccessMessage: true,
        data: data,
        descriptionField: "",
      });
    };

    return {
      randomTheoryQuestion,
      randomTheoryQuestionLoading,
      goToNext,
      onAnswer,
    };
  },
});
